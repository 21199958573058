import React from "react";

import logo from "./assets/carsmart-logo.png";
import ss from "./assets/carsmart-ss.png";
import Footer from "./Footer";
import { routes } from "./routes";

const CarSmartPage = () => {
  return (
    <div>
      <div className="items-center justify-center py-20 md:h-screen bg-gradient-to-br from-white to-gray-200 flex flex-col md:flex-row">
        <div className="order-last md:order-first flex items-start justify-center md:justify-end w-3/7 md:mx-8 md:block mt-20 md:mt-0">
          <img className="w-64 rounded-lg shadow-xl" src={ss} />
        </div>

        <div className="px-6 w-4/7 md:mx-8 md:px-0">
          <div>
            <img
              src={logo}
              className="flex flex-col w-32 rounded-lg shadow-2xl md:w-20"
            />
          </div>
          <div className="mt-10 font-lato">
            <p className="text-4xl md:text-4xl font-medium mt-10 max-w-sm">
              Too much car information to track?
            </p>
            <p className="text-4xl md:text-5xl font-semibold mt-4 text-blue-600">
              Meet <span className="text-blue-600">Car Smart.</span>
            </p>

            <p className="mt-10 text-xl text-gray-400">
              We'll handle all the details so you don't have to.
              <br /> Simplify your garage, with Car Smart.
            </p>
          </div>
          <div className="mt-5">
            <a
              target="_blank"
              href={routes.carSmartAppStore}
              className="mt-5 shadow ios-badge"
            ></a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CarSmartPage;
