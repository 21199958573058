import React from "react";
import Footer from "./Footer";
import logo from "./assets/zabaan-word-black.png";
import mockup from "./assets/zabaan-mockup-clear.png";
import { routes } from "./routes";

const ZabaanPage = () => {
  return (
    <div>
      <div className="md:h-screen px-6 flex-col flex min-h-screen bg-white">
        <div className="max-w-5xl px-6 flex mx-auto py-6">
          <div className="flex-1 flex justify-center">
            <img src={logo} className="h-8 sm:h-10" alt="Logo." />
          </div>
        </div>

        <div className="md:flex flex-1 items-center justify-center py-12 md:py-0 md:-mt-10 md:px-6">
          <div className="">
            <p
              className="text-5xl md:text-7xl font-medium leading-none"
              style={{ color: "#0f275c" }}
            >
              Transliterate <br />
              <span className="text-primary">with ease</span>
            </p>
            <p className="text-xl md:text-3xl max-w-md font-light mt-5 leading-tight text-gray-900">
              Zabaan allows you to easily transliterate between English (Roman)
              and Urdu text.
            </p>
            {/* eslint-disable-next-line */}
            <a
              target="_blank"
              rel="noreferrer"
              href={routes.zabaanAppStore}
              className="ios-badge mt-5"
            ></a>
          </div>

          <div className="mt-20 md:mt-0 md:max-w-xl max-w-md flex items-start mx-auto md:mx-0">
            <img src={mockup} alt="Mockup." className="img-shadow" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ZabaanPage;
