import React from "react";
import screenshot from "./assets/whitakers-ss.png";
import logo from "./assets/wwicon1024pt.png";
import Footer from "./Footer";
import { routes } from "./routes";

const WhitakersPage = () => {
  return (
    <div>
      <div className="items-center justify-center py-20 md:h-screen bg-gradient-to-r from-blue-500 to-blue-800 flex flex-col md:flex-row">
        <div className="order-last md:order-first items-start flex justify-center md:justify-end w-3/7 md:mx-8 md:block mt-20 md:mt-0">
          <img className="w-64 rounded-lg shadow-xl" src={screenshot} />
        </div>

        <div className="px-6 w-4/7 md:mx-8 md:px-0">
          <div>
            <img
              src={logo}
              className="flex flex-col w-32 rounded-lg shadow-2xl md:w-20"
            />
          </div>
          <div className="mt-10 font-lato">
            <p className="text-4xl font-semibold leading-none text-white md:text-6xl">
              Whitaker's Words
            </p>
            <p className="mt-2 text-4xl leading-tight text-gray-200">
              A simple, easy-to-use, latin dictionary.
            </p>
          </div>
          <div className="mt-5">
            <a
              target="_blank"
              href={routes.whitakersAppStore}
              className="mt-5 shadow ios-badge"
            ></a>
          </div>
        </div>
      </div>

      <div className="">
        <div className="max-w-6xl px-6 py-16 mx-auto md:px-12">
          <div className="items-start justify-center -mt-8 md:flex md:-mx-8 md:mt-0">
            <div className="flex-1 mt-8 md:mx-8 md:mt-0">
              <img
                className="h-20 mx-auto"
                src="https://image.flaticon.com/icons/svg/1584/1584892.svg"
              />

              <div className="mt-4">
                <p className="text-center text-xl font-medium text-gray-900">
                  Rapid translation
                </p>
                <p className="text-center mt-3 font-light text-gray-600 text-md">
                  Our engine is faster than ever, bringing you accurate
                  definitions within <em>milliseconds</em> of searching.
                </p>
              </div>
            </div>

            <div className="flex-1 mt-8 md:mx-8 md:mt-0">
              <img
                className="h-20 mx-auto"
                src="https://image.flaticon.com/icons/svg/3043/3043927.svg"
              />

              <div className="mt-4">
                <p className="text-center text-xl font-medium text-gray-900">
                  Easy searching
                </p>
                <p className="text-center mt-3 font-light text-gray-600 text-md">
                  We look through tens of thousands of common searches to bring
                  you the best results as quickly as possible.
                </p>
              </div>
            </div>

            <div className="flex-1 mt-8 md:mx-8 md:mt-0">
              <img
                className="h-20 mx-auto"
                src="https://image.flaticon.com/icons/svg/864/864685.svg"
              />

              <div className="mt-4">
                <p className="text-center text-xl font-medium text-gray-900">
                  Accurate definitions
                </p>
                <p className="text-center mt-3 font-light text-gray-600 text-md">
                  Whitaker's Words uses the best resource for Latin translation
                  in the modern era.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default WhitakersPage;
