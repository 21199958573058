import React from "react";
import Footer from "./Footer";
import "./home.css";

const HomePage = () => {
  return (
    <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>

      <div className="max-w-6xl mx-auto px-4 min-h-screen flex justify-center items-center">
        <div className="">
          <div className="pt-10">
            <p className="text-5xl md:text-7xl font-medium text-blue-500">
              Wala Ventures.
            </p>
            <p className="text-4xl md:text-5xl text-white font-medium mt-4 md:mt-0">
              A modern iOS development team.
            </p>
          </div>

          <div className="py-10 md:pt-20">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-fancy hover:-translate-y-1 duration-1000 ease-out transition-all transform shadow-md hover:shadow-lg group rounded-md md:p-8 p-3">
                <p className="text-2xl md:text-3xl text-gray-400 duration-1000 ease-out mb-2 group-hover:text-white transition-all">
                  Whitakers Words
                </p>
                <p className="text text-gray-500 mb-4">
                  A simple, easy-to-use, latin dictionary.
                </p>
                <a
                  href="/app/whitakers"
                  className="text-blue-500 hover:text-blue-400 transition-all font-light text-sm"
                >
                  Learn more →
                </a>
              </div>
              <div className="bg-fancy hover:-translate-y-1 duration-1000 ease-out transition-all transform shadow-md hover:shadow-lg group rounded-md md:p-8 p-3">
                <p className="text-2xl md:text-3xl text-gray-400 duration-1000 ease-out mb-2 group-hover:text-white transition-all">
                  Car Smart
                </p>
                <p className="text text-gray-500 mb-4">
                  Organize all your car information.
                </p>
                <a
                  href="/app/car-smart"
                  className="text-blue-500 hover:text-blue-400 transition-all font-light text-sm"
                >
                  Learn more →
                </a>
              </div>
              <div className="bg-fancy hover:-translate-y-1 duration-1000 ease-out transition-all transform shadow-md hover:shadow-lg group rounded-md md:p-8 p-3">
                <p className="text-2xl md:text-3xl text-gray-400 duration-1000 ease-out mb-2 group-hover:text-white transition-all">
                  Zabaan
                </p>
                <p className="text text-gray-500 mb-4">
                  Transliterate from Urdu to English.
                </p>
                <a
                  href="/app/zabaan"
                  className="text-blue-500 hover:text-blue-400 transition-all font-light text-sm"
                >
                  Learn more →
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
