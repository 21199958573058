import React from "react";
import HomePage from "./HomePage";
import { useRoutes } from "hookrouter";
import WhitakersPage from "./WhitakersPage";
import "./index.css";
import ZabaanPage from "./ZabaanPage";
import CarSmartPage from "./CarSmartPage";

const routes = {
  "/": () => <HomePage />,
  "/app/whitakers": () => <WhitakersPage />,
  "/app/zabaan": () => <ZabaanPage />,
  "/app/car-smart": () => <CarSmartPage />,
};

const App = () => {
  const routeResult = useRoutes(routes);
  return routeResult;
};

export default App;
