import React from "react";

const Footer = () => {
  return (
    <div className="">
      <div className="py-6 px-6 bg-gray-50 border-t border-gray-100">
        <p className="text-sm text-gray-400 text-center">
          &copy; Wala Ventures 2020.{" "}
          <a href="https://forms.gle/Fu7nU24fh7b8R87g8" target="_blank">
            Contact us
          </a>{" "}
          for more info.
        </p>
      </div>
    </div>
  );
};

export default Footer;
